import React, {useMemo, useState} from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    List,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    ButtonGroup, Tooltip,
} from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import {ArrowUpward, ArrowDownward} from '@mui/icons-material';
import Screen from '../../components/Screen/Screen';
import RouteInfoExtended from '../../components/Dashboard/RouteInfoExtended';
import useOrders from "../../lib/hooks/useOrders";
import {DataGrid} from "@mui/x-data-grid";
import {prettyDateTime, prettyDate, toCapitalizeFromUnderScore, truncate} from "../../lib/helpers/helpers";

const columns = [
    { field: 'id', headerName: 'ID', width: 75,
        renderCell: (params) => {
            return (
                <Tooltip title={params.row.id}>
                    <span>{truncate(params.row.id, 100)}</span>
                </Tooltip>
            );
        } },
    {
        field: 'status',
        headerName: 'Status',
        width: 100,
        renderCell: (params) => (
            <strong style={{ color: params.row.quote.status === 'hold' ? 'red' : params.row.quote.status === 'pending' ? 'orange' : 'green' }}>
                {toCapitalizeFromUnderScore(params.row.quote.status)}
            </strong>
        ),
    },
    {
        field: 'created_at',
        headerName: 'Created Date',
        width: 100,
        renderCell: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
        field: 'vehicles',
        headerName: 'Vehicles',
        width: 110,
        renderCell: (params) => {
            const vehicles = params.row.vehicles; // assuming 'vehicles' is an array in each row's data
            const vehiclesDisplay = vehicles.map(vehicle => `${vehicle.year} ${vehicle.make || 'N/A'} ${vehicle.model || 'N/A'}`).join(', ');

            return (
                <Tooltip title={vehiclesDisplay}>
                    <span>{truncate(vehiclesDisplay, 150)}</span>
                </Tooltip>
            );
        }
    },
    {
        field: 'desired_pickup_datetime',
        headerName: 'Pickup Date',
        width: 110,
        renderCell: (params) => {
            const content = params.row?.quote?.desired_pickup_datetime;
            return (
                <Tooltip title={prettyDateTime(content)} placement="top">
                    <span>{prettyDate(content)}</span>
                </Tooltip>
            );
        },
    },
    {
        field: 'desired_delivery_datetime',
        headerName: 'Delivery Date',
        width: 110,
        renderCell: (params) => {
            const content = params.row?.quote?.desired_delivery_datetime;
            return (
                <Tooltip title={prettyDateTime(content)} placement="top">
                    <span>{prettyDate(content)}</span>
                </Tooltip>
            );
        },
    },
    {
        field: 'pickupLocation',
        headerName: 'Pickup Location',
        width: 150,
        renderCell: (params) => {
            const pickup = params.row.locations.find(loc => loc.type === 'pickup');
            const content = pickup
                ? `${pickup.address}, ${pickup.city}, ${pickup.state_code} ${pickup.postal_code}`
                : 'N/A';
            return (
                <Tooltip title={content} placement="top">
                    <span>{content}</span>
                </Tooltip>
            );
        },
    },
    {
        field: 'deliveryLocation',
        headerName: 'Delivery Location',
        width: 150,
        renderCell: (params) => {
            const dropoff = params.row.locations.find(loc => loc.type === 'dropoff');
            const content = dropoff
                ? `${dropoff.address}, ${dropoff.city}, ${dropoff.state_code} ${dropoff.postal_code}`
                : 'N/A';
            return (
                <Tooltip title={content} placement="top">
                    <span>{content}</span>
                </Tooltip>
            );
        },
    },
    {
        field: 'driver',
        headerName: 'Driver',
        width: 200,
        renderCell: (params) => {
            const driver = params.value[0]; // Accessing the first driver from the array
            const content = driver ? `${driver.name} (${driver.carrier.name})` : 'No Driver Assigned';
            return (
                <Tooltip title={content} placement="top">
                    <span>{content}</span>
                </Tooltip>
            );
        },
    },/*
    {
        field: 'customer',
        headerName: 'Customer',
        width: 110,
    },*/
    {
        field: 'updated_at',
        headerName: 'Updated Date',
        width: 110,
        renderCell: (params) => {
            const content = params.value;
            return (
                <Tooltip title={prettyDateTime(content)} placement="top">
                    <span>{prettyDate(content)}</span>
                </Tooltip>
            );
        }
    },
];
const ShipmentDashboard = () => {
    const {data, isLoading, isRefetching, refetch} = useOrders()
    const [viewMode, setViewMode] = useState('list' || 'table');
    const [sort, setSort] = useState('created_at');
    const [sortAsc, setSortAsc] = useState(false); // Assuming true means ascending

    const handleSortChange = (event) => {
        setSort(event.target.value);
    };

    // Function to sort data
    const sortedData = useMemo(() => {
        if (!data || !Array.isArray(data)) {
            return [];
        }
        const sorter = (a, b) => {
            let valueA = a[sort];
            let valueB = b[sort];

            // Example handling for dates, strings, or numbers:
            if (valueA instanceof Date && valueB instanceof Date) {
                // Sort dates
                return sortAsc ? valueA - valueB : valueB - valueA;
            } else if (typeof valueA === 'string' && typeof valueB === 'string') {
                // Sort strings
                return sortAsc ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            } else {
                // Default to numeric sort
                return sortAsc ? valueA - valueB : valueB - valueA;
            }
        };

        return [...data].sort(sorter);
    }, [data, sort, sortAsc]);

    return (
        <Screen title="Shipments">
            <AppBar position="static" color="default" elevation={1}>
                <Toolbar>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        {sortedData.length} orders
                    </Typography>
                    <ButtonGroup variant="text" color="inherit">
                        <IconButton onClick={() => setViewMode('list')} color={viewMode === 'list' ? 'primary' : 'inherit'}>
                            <ViewModuleIcon/>
                        </IconButton>
                        <IconButton onClick={() => setViewMode('table')} color={viewMode === 'table' ? 'primary' : 'inherit'}>
                            <ViewListIcon/>
                        </IconButton>
                    </ButtonGroup>
                </Toolbar>
                {viewMode === 'list' && <Toolbar>
                    <FormControl size="small" variant="outlined" style={{marginRight: 8}}>
                        <InputLabel id="sort-label">Sort by</InputLabel>
                        <Select
                            labelId="sort-label"
                            id="sort-select"
                            value={sort}
                            label="Sort by"
                            onChange={handleSortChange}
                        >
                            <MenuItem value="created_at">Created Date</MenuItem>
                            <MenuItem value="updated_at">Updated Date</MenuItem>
                            <MenuItem value="actual_pickup_date_time">Pickup Date</MenuItem>
                            <MenuItem value="actual_delivery_date_time">Delivery Date</MenuItem>
                        </Select>
                    </FormControl>
                    <IconButton onClick={() => setSortAsc(!sortAsc)}>
                        {sortAsc ? <ArrowUpward/> : <ArrowDownward/>}
                    </IconButton>
                </Toolbar>}
            </AppBar>
            {viewMode === 'list' ?
            <>
                <List>
                    {sortedData?.map((shipment) => (
                        <RouteInfoExtended layout={'list'} overRideQuoteOrder={shipment} />
                    ))}
                </List>
            </>
            :
                <>
                    <DataGrid
                        columns={columns}
                        rows={sortedData}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableSelectionOnClick
                    />
                </>

            }
        </Screen>
    );
};

export default ShipmentDashboard;
